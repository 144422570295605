import React, { useState, useMemo, useEffect } from 'react'
import { Connection, PublicKey } from "@solana/web3.js";
import { wait } from 'awaitress'

const Airdrop = () => {
  const [addr, setAddr] = useState('');
  const [bal, setBal] = useState('');
  const [started, setStarted] = useState(false)
  const [error, setError] = useState('');

  const conn = useMemo(() => new Connection('https://api.devnet.solana.com'), []);

  useEffect(() => {
    if (addr) {
      conn.getBalance(new PublicKey(addr)).then((balance) => {
        const balSol = Math.round(balance / 10 ** 8) / 10;

        setBal(balSol.toString());
      }) 
    }
  }, [addr])

  async function request() {
    const acc = new PublicKey(addr);

    setStarted(true);

    while (true) {
      try {
        await conn.requestAirdrop(acc, 10 ** 9);
      } catch (e: any) {
        setError(e.message);
      }

      const balance = await conn.getBalance(acc);

      const balSol = Math.round(balance / 10 ** 8) / 10;

      setBal(balSol.toString());

      await wait(15000);
    }
  }

  return (
    <div>
      <h2>Sol Dev Faucet</h2>
      <p>Sol Dev Faucet has a rate limit of 1 SOL per 15  and also a daily limit of 25 SOL per address.</p>
      <p>This tool aims to slowly request up to 25 SOL for you. Afterwards it will show an error and you will have to wait another day to get more.</p>
      <div>
        <label>Address: </label>
        <input onChange={(e) => setAddr(e.target.value)} value={addr} />
        <button disabled={started} onClick={request}>{!started ? 'Get': 'Fetching'}</button>
      </div>
      <div>
        <div>Balance: {bal}</div>
        <div>{error}</div>
      </div>
    </div>
  )
}

export default Airdrop
