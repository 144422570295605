import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from "./Apps/Home/Home";
import SecreyKey from "./Apps/SecretKey/SecretKey";
import Airdrop from "./Apps/Airdrop/Airdrop"
import './App.css'
import SPLTokens from "./Apps/SPLTokens/SPLTokens";

export default function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul className="nav">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/secret-key">Secret Key Converter</Link>
            </li>
            <li>
              <Link to="/airdrop">Sol Faucet</Link>
            </li>
            <li>
              <Link to="/spl-tokens">SPL Tokens</Link>
            </li>
          </ul>
        </nav>

        <div className="body">
          <Switch>
            <Route path="/spl-tokens">
              <SPLTokens />
            </Route>
            <Route path="/airdrop">
              <Airdrop />
            </Route>
            <Route path="/secret-key">
              <SecreyKey />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}