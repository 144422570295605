import React, { ChangeEvent, useState } from 'react'
import { Keypair } from '@solana/web3.js'
import { convertSecretKey, getArrayFormat } from '../../utils/convertSecretKey'

const SecreyKey = () => {
  const [value, setValue] = useState('')

  const handleRandomKey = () => {
    const kp = new Keypair();

    setValue(`[${kp.secretKey.toString()}]`);
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    try {
      
      setValue(e.target.value);
    } catch (e) {
      setValue('Incorrect Input')
    }
  }

  let pubKey = '';
  let arrFormat = '';
  if (value) {
    try {
      pubKey = convertSecretKey(value);
      arrFormat = getArrayFormat(value);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div>
      <h2>Secret Key Converter</h2>
      <button onClick={handleRandomKey}>Get Random Key</button>
      <div>Enter your secret key here and it will show you the public key</div>
      <div>Either in array u8 format or in base58 format</div>
      <textarea style={{ resize: 'none', height: '250px', width: '80%' }} value={value} onChange={handleChange} />
      <div>Public Key: <b>{pubKey}</b></div>
      <div>
        <div>Array Format</div>
        {arrFormat}
      </div>
    </div>
  )
}

export default SecreyKey
