import React from 'react'

const SPLTokens = () => {
  return (
    <div>
      Coming Soon
    </div>
  )
}

export default SPLTokens
