import bs58 from 'bs58';
import { Keypair } from '@solana/web3.js';

export const convertSecretKey = (str: string): string => {
  let decoded;
  if (!str.startsWith('[')) {
    decoded = bs58.decode(str);
  } else {
    const data = JSON.parse(str);

    decoded = new Uint8Array(data);
  }
  
  const keypair = Keypair.fromSecretKey(decoded);

  return keypair.publicKey.toString();
}

export const getArrayFormat = (str: string): string => {
  if (!str.startsWith('[')) {
    const decoded = Uint8Array.from(bs58.decode(str));
    
    return `[${decoded.toString()}]`;
  } else {
    return str;
  }
}
