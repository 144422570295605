import React from 'react'

const Home = () => {
  return (
    <div>
      <h2>Home</h2>
      This page contains a compilation of useful tools. 
    </div>
  )
}

export default Home
